import api from '@/services/api'
import { SupportForm } from '@/services/support/types'
import { AxiosError } from 'axios'

const createSupportTicket = async (payload: SupportForm): Promise<string | AxiosError> => {
  const formData = new FormData()
  formData.append('topic', payload.topic)
  if (payload.description) {
    formData.append('description', payload.description)
  }
  if (payload.widget_id) {
    formData.append('widget_id', payload.widget_id)
  }
  if (payload.playlist_id) {
    formData.append('playlist_id', payload.playlist_id)
  }
  if (payload.url) {
    formData.append('url', payload.url)
  }
  if (payload.files.length) {
    formData.append('file', payload.files[0])
  }
  try {
    const response = await api.post<string>('/support', formData)
    return response.data
  } catch (error) {
    throw error as AxiosError
  }
}

export default {
  createSupportTicket
}
