export enum ModalType {
  PACKAGE_DUPLICATE_MODAL = 'package_duplicate',
  WIDGET_DUPLICATE_MODAL = 'widget_duplicate',
  WIDGET_DELETE_MODAL = 'widget_delete',
  WIDGET_EDIT_MODAL = 'widget_edit',
  WIDGET_CREATE_MODAL = 'widget_create',
  ADD_FOLDER_MODAL = 'add_folder',
  EDIT_FOLDER_MODAL = 'edit_folder',
  SETTINGS_ADD_USER_MODAL = 'add_user',
  SETTINGS_EDIT_USER_MODAL = 'edit_user',
  VIDEOS_EDIT_CHANNEL_MODAL = 'edit_channel',
  VIDEOS_ADD_VIDEO_MODAL = 'add_video',
  VIDEOS_ADD_STREAM = 'add_stream',
  VIDEOS_DELETE_VIDEO_MODAL = 'delete_video',
  VIDEOS_MY_IMPORT_EDIT_STREAM = 'my_import_edit_stream',
  VIDEOS_FOLDER_DELETE_VIDEO = 'folder_delete_video',
  VIDEOS_EDIT_VIDEO = 'edit_video',
  PLAYLISTS_EDIT_PLAYLIST = 'edit_playlist',
  DOMAINS_ADD_DOMAIN_MODAL = 'add_domain',
  DOMAINS_ADD_EXCLUDE_DOMAIN_MODAL = 'add_exclude_domain',
  FOLDERS_DELETE_FOLDER_MODAL = 'folders_delete_folder',
  STREAMS_DELETE_STREAM_MODAL = 'streams_delete_stream',
  PLAYLISTS_DUPLICATE_PLAYLIST_MODAL = 'playlists_duplicate_playlist',
  PLAYLISTS_DELETE_PLAYLIST_MODAL = 'playlists_delete_playlist',
  ACCOUNTS_EDIT_ACCOUNT_MODAL = 'accounts_edit_account',
  PLAYLISTS_ADD_VIDEOS = 'playlists_add_videos', // add videos to existing playlist + add videos to new playlist
  OFFERED_PACKAGES_PACKAGE_MODAL = 'offered_packages_package',
  OFFERED_PACKAGES_All_PACKAGE_MODAL = 'offered_packages_all_packages',
  DOMAINS_DELETE_EXCLUDE_DOMAIN_MODAL = 'delete_exclude_domain',
  DOMAINS_DELETE_DOMAIN_MODAL = 'delete_domain',
  PAIRED_URLS_DELETE_PAIR_URL_MODAL = 'delete_paired_url',
  PACKAGE_ADD_PACKAGE_MODAL = 'add_package',
  PACKAGE_EDIT_PACKAGE_MODAL = 'edit_package',
  CONTACT_SUPPORT = 'contact_support',
  CONTACT_SUPPORT_SUBMIT_ERROR = 'contact_support_submit_error',
  CONTACT_SUPPORT_SUBMIT_SUCCESS = 'contact_support_submit_success',
  PLAYER_MODAL = 'player_modal',
  MULTISELECT_CREATE_FOLDER_MODAL = 'multiselect_create_folder_modal',
  COUNTRY_RESTRICTION_MODAL = 'country_restriction_modal',
  CREATE_FOLDER_MODAL = 'create_folder_modal',
  DISCARD_CHANGES = 'discard_changes_modal',
  IMPORT_FINISH = 'import_success',
  UPLOAD_VIDEO_FINISH = 'upload_video',
  USERS_DELETE_USER_MODAL = 'users_delete_user'
}
