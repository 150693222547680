import type { InjectionKey } from 'vue'
import type { DataActions, ContentDataRouterConfig } from '@/interfaces'

export const PLAYLIST_ACTIONS_INJECTION_KEY: InjectionKey<DataActions> = Symbol('playlist actions')
export const DOMAINS_ACTIONS_INJECTION_KEY: InjectionKey<DataActions> = Symbol('domains actions')
export const WIDGET_ACTIONS_INJECTION_KEY: InjectionKey<DataActions> = Symbol('widget actions')
export const EXCLUDED_DOMAINS_ACTIONS_INJECTION_KEY: InjectionKey<DataActions> = Symbol('excluded actions')
export const OFFERED_PACKAGES_ACTIONS_INJECTION_KEY: InjectionKey<DataActions> = Symbol('offered packages actions')
export const MY_IMPORTS_ACTIONS_INJECTION_KEY: InjectionKey<DataActions> = Symbol('myimports actions')
export const CONTENT_ROUTER_INJECTION_KEY: InjectionKey<ContentDataRouterConfig> = Symbol('content router')
export const VIDEO_ACTIONS_INJECTION_KEY: InjectionKey<DataActions> = Symbol('video router')
export const INVENTORY_PAIRED_URLS_INJECTION_KEY: InjectionKey<DataActions> = Symbol('paired url actions')
export const MY_PACKAGES_ACTIONS_INJECTION_KEY: InjectionKey<DataActions> = Symbol('my packages actions')
export const SETTINGS_USERS_ACTIONS_INJECTION_KEY: InjectionKey<DataActions> = Symbol('setting users actions')

export const KeyMap: { [key: string]: number } = {
  TAB: 9,
  ENTER: 13,
  ESC: 27,
  SPACE: 32,
  END: 35,
  HOME: 36,
  LEFT: 37,
  UP: 38,
  RIGHT: 39,
  DOWN: 40,
  P: 80,
  C: 67,
  F: 70,
  M: 77,
  SEMI_COLON: 186,
  COMMA: 188,
  PERIOD: 190
}
