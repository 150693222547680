import { CRUD, DataType, VideoEditVideoSettingsScreen } from '@/interfaces'
import { RouteRecordRaw } from 'vue-router'

const videosRoutes: RouteRecordRaw[] = [
  {
    path: 'content-sources',
    name: 'content-sources',
    // component: () => import('@/views/content/MyImportsPage.vue'),
    meta: {
      title: 'sources',
      insideLayout: true
    },
    props: true,
    children: [
      {
        path: 'edit/:id',
        name: 'cd-edit-stream',
        component: () => import('@/components/content/videos/my-imports/edit/MyImportsEditStream.vue'),
        props: true,
        meta: {
          title: 'stream'
        },
        children: [
          {
            path: 'closeunsaved',
            name: 'edit-closeUnsavedStream',
            component: () => import('@/components/modals/custom-confirmation/CustomConfirmation.vue'),
            meta: {
              title: 'stream'
            },
            props: { type: DataType.ContentSources, action: CRUD.ConfirmClose }
          },
          {
            path: 'deactivate',
            name: 'edit-deactivatecontent',
            component: () => import('@/components/modals/custom-confirmation/CustomConfirmation.vue'),
            meta: {
              title: 'stream'
            },
            props: { type: DataType.ContentSources, action: CRUD.Deactivate }
          }
        ]
      },
      {
        path: 'delete-stream/:id',
        name: 'cd-delete-stream',
        component: () => import('@/components/modals/custom-confirmation/CustomConfirmation.vue'),
        meta: {
          title: 'stream'
        },
        props: { type: DataType.ContentSources, action: CRUD.Delete }
      }
    ]
  },
  {
    path: 'folders',
    name: 'folders',
    // component: () => import('../../views/dashboard/MyVideosPage.vue'),
    meta: {
      title: 'folders'
    },
    props: true,
    children: [
      {
        path: 'view/:folderId',
        name: 'view-folder',
        component: () => import('@/views/content/FoldersPage.vue'),
        props: true,
        children: [
          {
            path: 'folder-video-edit/:id',
            name: 'folder-video-edit',
            component: () => import('@/components/content/videos/EditVideoSettings.vue'),
            props: true,
            meta: {
              title: 'folders',
              modal: true,
              hideRouter: true
            },
            children: [
              {
                path: 'closeunsaved',
                name: 'content-folder-edit-video-closeUnsaved',
                component: () => import('@/components/modals/custom-confirmation/CustomConfirmation.vue'),
                meta: {
                  title: 'folders'
                },
                props: { type: DataType.Video, action: CRUD.ConfirmClose }
              }
            ]
          }
        ],
        meta: {
          title: 'folders',
          fullscreen: true
        }
      },
      {
        path: 'edit/:id',
        name: 'edit-folder',
        component: () => import('@/components/content/videos/folders/edit/EditFolder.vue'),
        props: true,
        meta: {
          title: 'folders',
          modal: true
        },
        children: [
          {
            path: 'closeunsaved',
            name: 'edit-closeUnsavedFolder',
            component: () => import('@/components/modals/custom-confirmation/CustomConfirmation.vue'),
            meta: {
              title: 'folders'
            },
            props: { type: DataType.Folder, action: CRUD.ConfirmClose }
          }
        ]
      },
      {
        path: 'delete/:id',
        name: 'delete-folder',
        component: () => import('@/components/modals/custom-confirmation/CustomConfirmation.vue'),
        meta: {
          title: 'folders',
          modal: true
        },
        props: { type: DataType.Folder, action: CRUD.Delete }
      }
    ]
  },
  {
    path: 'videos',
    name: 'videos',
    // component: () => import('../../views/dashboard/MyVideosPage.vue'),
    children: [
      {
        path: 'edit/:id',
        name: 'video-edit',
        component: () => import('@/components/content/videos/EditVideoSettings.vue'),
        props: true,
        meta: {
          title: 'videos',
          modal: true
        },
        beforeEnter: (to, from, next) => {
          if (!to.query.tab) {
            next({
              name: to.name,
              params: to.params,
              query: { ...to.query, tab: VideoEditVideoSettingsScreen.AddToPlaylist },
              replace: true
            })
          } else {
            next()
          }
        },
        children: [
          {
            path: 'closeunsaved',
            name: 'content-video-edit-closeUnsaved',
            component: () => import('@/components/modals/custom-confirmation/CustomConfirmation.vue'),
            meta: {
              title: 'videos'
            },
            props: { type: DataType.Video, action: CRUD.ConfirmClose }
          }
        ]
      },
      {
        path: 'createplaylist',
        name: 'videos-createplaylist',
        component: () => import('@/components/modals/createplaylist/CreatePlaylist.vue'),
        meta: {
          title: 'playlists',
          modal: true
        },
        children: [
          {
            path: 'closeunsaved',
            name: 'content-closeUnsavedPlaylist',
            component: () => import('@/components/modals/custom-confirmation/CustomConfirmation.vue'),
            meta: {
              title: 'videos'
            },
            props: { type: DataType.Playlist, action: CRUD.ConfirmClose }
          }
        ]
      },
      {
        path: 'details/:id',
        name: 'videos-details',
        component: () => import('@/components/content/library/details/VideoDetails.vue'),
        meta: {
          title: 'videos',
          modal: true
        },
        children: [
          {
            path: 'share',
            name: 'videos-video-details-share',
            component: () => import('@/components/content/library/VideoShare.vue'),
            meta: {
              title: 'urls'
            }
          }
        ]
      },
      {
        path: 'add',
        name: 'add-video',
        component: () => import('@/components/content/videos/my-imports/add/AddVideo.vue'),
        meta: {
          title: 'addvideo',
          fullscreen: true
        },
        children: [
          {
            path: 'closeunsaved',
            name: 'add-close-unsaved-video',
            component: () => import('@/components/modals/custom-confirmation/CustomConfirmation.vue'),
            meta: {
              title: 'playlists'
            },
            props: { type: DataType.Video, action: CRUD.CloseCreate }
          }
        ]
      },
      {
        path: 'addstream',
        name: 'add-stream',
        component: () => import('@/components/content/videos/my-videos/add-stream/AddStream.vue'),
        meta: {
          title: 'addstream',
          fullscreen: true
        },
        children: [
          {
            path: 'closeunsaved',
            name: 'add-close-unsaved-stream',
            component: () => import('@/components/modals/custom-confirmation/CustomConfirmation.vue'),
            meta: {
              title: 'playlists'
            },
            props: { type: DataType.ContentSources, action: CRUD.CloseCreate }
          }
        ]
      },
      {
        path: 'delete/:id',
        name: 'delete-video',
        component: () => import('@/components/modals/custom-confirmation/CustomConfirmation.vue'),
        meta: {
          title: 'videos',
          modal: true
        },
        props: { type: DataType.Video, action: CRUD.Delete }
      }
    ],
    meta: {
      title: 'videos'
    }
  },
  {
    path: 'channel',
    name: 'channel',
    redirect: 'channel/preview',
    props: true,
    children: [
      {
        path: 'preview',
        name: 'preview-channel',
        component: () => import('@/components/content/videos/my-channel/PreviewChannel.vue'),
        props: true,
        children: [
          {
            path: 'edit',
            name: 'edit-channel',
            component: () => import('@/components/content/videos/my-channel/edit/EditChannel.vue'),
            props: true,
            meta: {
              title: 'channel',
              modal: true
            },
            children: [
              {
                path: 'closeunsaved',
                name: 'edit-closeUnsavedChannel',
                component: () => import('@/components/modals/custom-confirmation/CustomConfirmation.vue'),
                meta: {
                  title: 'channel'
                },
                props: { type: DataType.Channel, action: CRUD.ConfirmClose }
              }
            ]
          }
        ],
        meta: {
          title: 'channel',
          insideLayout: true
        }
      }
    ],
    meta: {
      title: 'channel',
      insideLayout: true
    }
  }
]

export default videosRoutes
