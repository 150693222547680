<template>
  <Popper
    v-bind="$attrs"
    :hover="hover"
    :open-delay="+openDelay"
    :close-delay="+closeDelay"
    :arrow="arrow"
    :offset-skid="offsetSkid"
    :offset-distance="offsetDistance"
    :arrow-padding="arrowPadding"
    :visible="shouldShowTooltip"
    :placement="placement"
    @click="onClick"
  >
    <!-- Render content based on props -->
    <template v-if="shouldRenderContent">
      <div ref="textElement" class="nowrap" :class="{ ellipsis: autoEllipsis }" :style="{ textWrap: contentTextWrap }">{{ getContent }}</div>
    </template>
    <!-- Fallback to default slot content -->
    <slot v-else />
    <!-- Render tooltip content based on condition -->
    <template v-if="shouldRenderTooltipContent" #content="slotProps">
      <div class="tooltip">
        <slot name="content" :style="{ fontSize: textSize + 'px!important' }" v-bind="slotProps">{{ props.content }}</slot>
      </div>
    </template>
  </Popper>
</template>

<script setup lang="ts">
import { computed, PropType, ref } from 'vue'
import Popper from 'vue3-popper'
import { shortenText } from '@/services/utils'
import { TextWrap } from '@/interfaces'
import { useWindowSize } from '@vueuse/core'

const { width } = useWindowSize()

const props = defineProps({
  placement: { type: String, default: 'top' },
  hover: { type: Boolean, default: true },
  openDelay: { type: Number, default: 50 },
  closeDelay: { type: Number, default: 70 },
  disabled: { type: Boolean, default: false },
  arrow: { type: Boolean, default: false },
  offsetSkid: { type: String, default: '0' },
  offsetDistance: { type: String, default: '8' },
  arrowPadding: { type: String, default: '0' },
  content: { type: String },
  textLength: { type: Number, default: 30 },
  textSize: { type: Number, default: 16 },
  autoEllipsis: { type: Boolean, default: false },
  contentTextWrap: { type: String as PropType<TextWrap>, default: 'nowrap' }
})
const textElement = ref()
const shouldRenderContent = computed(() => props.content !== undefined && props.content !== null && props.content !== '')
// Compute content to render based on length
const getContent = computed(() => {
  const content = props.content || ''
  return content.length > props.textLength && !props.autoEllipsis ? shortenText(content, props.textLength) : content
})
const shouldRenderTooltipContent = computed(() => {
  if (props.autoEllipsis && textElement.value) {
    width.value // just to trigger the computed again on resize
    return textElement.value?.scrollWidth > textElement.value?.clientWidth
  } else {
    const content = props.content || ''
    return content.length > props.textLength
  }
})
const shouldShowTooltip = computed(() => !!props.content)
const onClick = (e) => {
  if (e.target.classList.contains('popper') || e.target.parentElement.classList.contains('popper')) {
    e.stopPropagation()
    e.preventDefault()
  }
}
</script>

<style>
.nowrap {
  white-space: nowrap;
}

.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
}

.popper {
  background-color: transparent;
  display: flex;
  justify-content: center;
  width: fit-content;
  height: fit-content;
  cursor: pointer;
  border: 1px solid #9599c6;
  border-radius: 15px;
  word-break: break-word;
  flex-direction: column;
  align-items: flex-start;
  word-wrap: break-word;
  font-size: 14px;
  font-weight: normal;
  opacity: 1;
}
</style>

<style>
:root {
  --popper-theme-background-color: #ffffff;
  --popper-theme-background-color-hover: #ffffff;
  --popper-theme-text-color: #4c4c66;
  --popper-theme-border-width: 1px;
  --popper-theme-border-style: solid;
  --popper-theme-border-color: #c2c6cc;
  --popper-theme-border-radius: 6px;
  --popper-theme-box-shadow: 0 6px 30px -6px rgba(0, 0, 0, 0.25);
  --popper-theme-padding: 8px 10px;
  opacity: 1;
}
</style>
