import store from '@/store'
import i18n from '@/i18n'
import api from '@/services/api'

const generateThumbnailsForVideo = async (
  request: {
    time: string
    id?: string
    video_id?: string
  },
  showMessage = false
): Promise<{ thumbnails: string[] }> => {
  try {
    const a = await api.post('/thumbnail/video', request)
    if (showMessage) {
      store.dispatch('messages/addMessage', {
        message: i18n.global.t('action-success'),
        type: 'success'
      })
    }

    return a.data
  } catch (error) {
    if (showMessage) {
      if (error.response && error.response.data) {
        store.dispatch('messages/addMessage', {
          message: error.response.data.error.description,
          type: 'error'
        })
      } else {
        store.dispatch('messages/addMessage', {
          message: 'Error uploading image',
          type: error
        })
      }
    }
    throw error
  }
}
const uploadThumbnail = async (thumb: File, videoId: string, showSuccess: boolean) => {
  const bodyFormData = new FormData()
  bodyFormData.append('thumbnail', thumb)
  if (videoId) {
    bodyFormData.append('video_id', videoId)
  }
  try {
    const a = await api.post<{ thumbnail_url: string | string[] }>('/thumbnail/custom', bodyFormData)

    if (showSuccess) {
      store.dispatch('messages/addMessage', {
        message: i18n.global.t('action-success'),
        type: 'success'
      })
    }
    return a.data.thumbnail_url
  } catch (error) {
    if (error.response && error.response.data) {
      store.dispatch('messages/addMessage', { message: error.response.data.error.description, type: 'error' })
    } else {
      store.dispatch('messages/addMessage', { message: i18n.global.t('content-thumbnail-upload-error'), type: error })
    }
    return ''
  }
}

export default {
  generateThumbnailsForVideo,
  uploadThumbnail
}
