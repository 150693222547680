import { apiService } from '@/services'
import { GeneratePermissions } from './permissions'
import type { Permissions } from './permissions'
import type { LoginUser, UserInfo, UserRole, UserTokens } from '@/services/organization-user/types'
import type { Organization } from '@/services/organization/types'
import type { ContentChannel } from '@/services/channel/types'
import { Commit } from 'vuex'

/* eslint-disable camelcase */
export interface User {
  // eslint-disable-next-line camelcase
  notifications_key: string
  tokens: UserTokens
  user: UserInfo
  organization: Organization
  roles: UserRole[]
  playerLoaded: boolean
  organization_id: number
  tokens_updated: number
  permissions: Permissions
  channel_info: ContentChannel
  users: User[]
  activeUserIndex: number
  mode: string
  isRootUserAgency: boolean
  isLoggingFromAuth: false
}

function getDefaultUserInfo(): UserInfo {
  return {
    id: -1,
    email: '',
    first_name: '',
    last_name: '',
    phone_number: '',
    country_id: 0,
    language_id: 0,
    timezone: '',
    is_active: true,
    organization_id: -1,
    created_at: '',
    last_login: '0',
    color: 'rgb(256,256,256)',
    channel_handle: ''
  }
}

function getDefaultUserTokens(): UserTokens {
  return {
    access_token: '',
    refresh_token: '',
    expires: 0,
    refresh_expires: 0
  }
}

export const user = {
  namespaced: true,
  state: () => ({
    users: [], // Array to store user profiles
    isRootUserAgency: false, // Boolean to know what to set in UI
    mode: 'user', // String to check which account we are
    activeUserIndex: -1, // Current active user picked from user array
    notifications_key: '',
    tokens: getDefaultUserTokens(),
    user: getDefaultUserInfo(),
    organization: {},
    roles: [],
    playerLoaded: false,
    tokens_updated: 0,
    permissions: {},
    channel_info: null,
    isLoggingFromAuth: false
  }),
  mutations: {
    updateUserData(state, userData) {
      state.user = userData.user
      state.tokens = userData.tokens
      state.roles = userData.roles
      state.permissions = GeneratePermissions(userData.roles)
      state.notifications_key = userData.notifications_key
      state.tokens_updated = new Date().getTime()
    },
    setUserColor(state, color: string) {
      state.user.color = color
    },
    addUserToUsersList(state, userData) {
      state.users.push(userData)
    },

    setUserChannelHandle(state, handle: string) {
      state.user.channel_handle = handle
    },

    setIsRootUserAgency(state, res: boolean) {
      state.isRootUserAgency = res
    },

    setMode(state, res: string) {
      state.mode = res
    },

    removeRemoteUsers(state: User) {
      state.users = state.users.slice(0, 1)
    },

    setNewTokens(state: User, tokens: UserTokens) {
      state.tokens = tokens
      state.tokens_updated = new Date().getTime()
    },

    setUserInfo(state: User, userInfo: UserInfo) {
      state.user.first_name = userInfo.first_name
      state.user.last_name = userInfo.last_name
      state.user.email = userInfo.email
      state.user.phone_number = userInfo.phone_number
      state.user.country_id = userInfo.country_id
      state.user.language_id = userInfo.language_id
      state.user.timezone = userInfo.timezone
    },

    logout(state: User) {
      state.tokens = getDefaultUserTokens()
      state.user = getDefaultUserInfo()
      state.roles = []
      state.organization = {} as Organization
      state.tokens_updated = new Date().getTime()
      state.notifications_key = ''
      state.permissions = GeneratePermissions([])
      state.users = []
      state.activeUserIndex = 0
      state.mode = 'user'
      state.isRootUserAgency = false
      state.channel_info = null
      state.isLoggingFromAuth = false
    },
    setPlayerLoaded(state: { playerLoaded: boolean }, playerLoadedState: boolean) {
      state.playerLoaded = playerLoadedState
    },
    setOrgName(state: User, name: string) {
      state.organization.name = name
    },
    setOrgProfileImage(state: User, profileImage: string) {
      state.organization.profile_image = profileImage
    },
    setOrg(state: User, org: Organization) {
      state.organization = org
    },
    setChannelInfo(state: User, info: ContentChannel) {
      state.channel_info = info
    },
    setActiveUserIndex(state, index) {
      state.activeUserIndex = index
    },
    setIsLoginFromAuth(state, isFromAuth: boolean) {
      state.isLoggingFromAuth = isFromAuth
    }
  },
  actions: {
    switchUser(context, index) {
      context.commit('setActiveUser', index)
    },
    async extendOrgData(context) {
      try {
        const org = await apiService.organization.getOrganization(context.state.user.organization_id)
        context.commit('setOrg', org)
      } catch (error) {
        // Handle error if necessary
        console.error('Error while extending organization data:', error)
      }
    },
    setAccessToken({ commit, state }: { commit: Commit; state: User }, { userData }: { userData: LoginUser }): void {
      if (state.activeUserIndex !== -1) {
        commit('updateUserData', userData)
      }
    },
    setActiveUser({ commit, state }: { commit: Commit; state: User }, index: number) {
      commit('setActiveUserIndex', index)
      const activeUserData = state.users[index]
      commit('updateUserData', activeUserData)
    },
    addNewUser({ commit, state }: { commit: Commit; state: User }, userData: LoginUser) {
      commit('addUserToUsersList', userData)
      commit('updateUserData', userData)
      commit('setActiveUserIndex', state.users.length - 1)
    }
  },
  getters: {
    isRole: (state) => (role) => {
      return state.roles
        ?.map((r) => {
          return r.name
        })
        ?.includes(role)
    },
    getRoles: (state) => () => {
      return state.roles.map((r) => {
        return r
      })
    },
    getId: (state) => () => {
      return state.user.id
    },
    getOrganizationId: (state) => () => {
      return state.user.organization_id
    },
    getOrgProfileImage: (state) => () => {
      return state.organization.profile_image
    }
  }
}
