<template>
  <div class="full-width">
    <div ref="dataViewRow" class="row" :style="`grid-template-columns: ${columnsGridTemplate(props.columns)}`" @click="handleRowClick">
      <div class="head first" :class="shouldHideOnBreakpoint(props.columns, 'title')">
        <VideoComponent
          remove-add-button
          is-cover
          :item="props.item"
          :hover-animation="false"
          :with-title-events="false"
          :interactive="false"
          :show-time="false"
          :show-ownership="false"
          :show-top-text="false"
          :show-orientation="false"
          class="video-component"
        />
        <div class="video-title">
          <Tooltip :placement="'top'" :content="props.item.title" auto-ellipsis />
        </div>
      </div>
      <div :class="shouldHideOnBreakpoint(props.columns, 'upload_date')">{{ getDateString(item.upload_date) }}</div>

      <div class="head" :class="shouldHideOnBreakpoint(props.columns, 'pricing_model')">
        {{ item.pricing_model === 'free' ? $t('free') : $t('revshare') }}
      </div>
      <div class="head p-2" :class="shouldHideOnBreakpoint(props.columns, 'private')">
        <div
          class="exposure"
          :class="{
            private: item.private === true,
            public: item.private === false
          }"
        >
          <span>{{ $t('settings-sources-public-' + !item.private) }}</span>
        </div>
      </div>
      <div :class="shouldHideOnBreakpoint(props.columns, 'language_id')" class="head">
        {{ getDisplayLanguage(item.language_2_letters) }}
      </div>
      <div :class="shouldHideOnBreakpoint(props.columns, 'is_vertical')" class="head">
        <VideoLayoutOrientation :is-vertical="item.is_vertical" :tag-colors="TagColors.White" :size="TagSizes.Big" :show-img="disableIconInFolderView" />
      </div>
      <div :class="shouldHideOnBreakpoint(props.columns, 'duration_in_ms')" class="head">
        {{ formatDuration(item.duration_in_ms) }}
      </div>
      <ActionButtons :actions="actions" :is-visible="isRowHovered" :class="shouldHideOnBreakpoint(props.columns, 'actions')" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { LocationQueryRaw, useRoute, useRouter } from 'vue-router'
import { getDateString, getDisplayLanguage, formatDuration, debounce, openModalCustomConfirmation } from '@/services/utils'
import { computed, inject, ref } from 'vue'
import VideoComponent from '@/components/base/VideoComponent.vue'
import ActionButtons from '@/components/common/action-buttons/ActionButtons.vue'
import { checkPermission } from '@/store/permissions'
import { columnsGridTemplate, shouldHideOnBreakpoint } from '@/services/layoutUtils'
import { useElementHover } from '@vueuse/core'
import { useStore } from 'vuex'
import { uid } from 'uid'
import { Video } from '@/services/videos/types'
import Tooltip from '@/components/common/tooltips/Tooltip.vue'
import addVideosIconPlaceholder from '@/assets/playlists/playlist/add-videos-icon-placeholder.svg'
import { CRUD, DataActions, DataType, VideoEditVideoSettingsScreen } from '@/interfaces'
import { apiService } from '@/services'
import { useI18n } from 'vue-i18n'
import { VIDEO_ACTIONS_INJECTION_KEY } from '@/constants'
import { ModalType } from '@/components/modals/modals-util'
import { VideoLayoutOrientation } from '@/components/common'
import { TagColors, TagSizes } from '@/components/common/tag/utils'

const route = useRoute()
const store = useStore()
const image = new window.Image()
const props = defineProps(['columns', 'item'])

image.src = addVideosIconPlaceholder

const router = useRouter()
const inFolderView = computed(() => router.currentRoute.value.path.toString().includes('/folders/'))
const disableIconInFolderView = computed(() => !inFolderView.value)

const dataViewRow = ref()
const isRowHovered = useElementHover(dataViewRow)
const actions = [
  ...(!router.currentRoute.value.path.includes('/folders/view') && checkPermission('playlists')
    ? [
        {
          label: 'add',
          handler: () => {
            addVideoToPlaylist(props.item)
          },
          translationKey: 'add-to-playlist'
        }
      ]
    : []),
  {
    label: 'edit',
    handler: () => openVideoSettings(props.item, { tab: VideoEditVideoSettingsScreen.EditVideo }),
    translationKey: 'content-video-edit'
  },
  ...(checkPermission('widgets')
    ? [
        {
          label: 'embed',
          dropDownLabel: 'embedMenu',
          handler: () => openVideoSettings(props.item, { tab: VideoEditVideoSettingsScreen.Embeded }),
          translationKey: 'content-video-embed'
        }
      ]
    : []),
  {
    label: 'delete',
    handler: openDeleteVideo,
    translationKey: 'content-video-delete'
  }
]

function handleRowClick(e: MouseEvent, query: LocationQueryRaw = { tab: VideoEditVideoSettingsScreen.EditVideo }) {
  openVideoSettings(props.item, query)
}

const openVideoSettings = debounce((item: Video, query: LocationQueryRaw = {}) => {
  router.push({
    name: inFolderView.value ? 'folder-video-edit' : 'video-edit',
    params: { id: item.video_id },
    query
  })
}, 500)

const modalType = ModalType.VIDEOS_DELETE_VIDEO_MODAL
const { t } = useI18n()
const dataActions = inject<DataActions>(VIDEO_ACTIONS_INJECTION_KEY)
const deleteVideo = async (params) => {
  return apiService.videos
    .deleteVideo(params.id as string)
    .then(() => {
      if (dataActions?.clearSearch) dataActions.clearSearch()
      store.dispatch('messages/addMessage', {
        message: t('video-deleted'),
        type: 'success'
      })
    })
    .catch((err) => {
      console.warn(err)
    })
}

function addVideoToPlaylist(video: Video): void {
  const existingVideo = store.state.playlists?.selectedVideos?.find((v) => v.video_id === video.video_id)
  const existingLibraryVideoIndex = store.state.library.videos.findIndex((v) => v.video_id === video.video_id)

  if (existingVideo) {
    return
  }

  const clone = JSON.parse(JSON.stringify(video))
  clone.uid = uid()
  store.commit('playlists/selectVideo', clone)

  if (existingLibraryVideoIndex !== -1) {
    store.commit('library/updateVideo', {
      index: existingLibraryVideoIndex,
      updatedVideo: { ...video, selectedVideo: true }
    })
  }
  if (!route.path.includes('createplaylist')) router.push(route.path + '/createplaylist')
}

function openDeleteVideo() {
  openModalCustomConfirmation({ action: CRUD.Delete, type: DataType.Video, itemName: props.item.title, id: props.item.video_id, onConfirm: deleteVideo }, modalType)
}
</script>

<style scoped lang="scss">
.row {
  width: 100%;
  cursor: pointer;

  height: 86px;
  display: grid;
  grid-template-columns: 33% 11% 10% 12% 10% 13% auto;
  align-items: center;
  justify-items: start;

  > div {
    padding: 1rem;
    width: 100%;
  }

  &:hover {
    background-color: #f2f4fa;
  }

  .head {
    display: flex;
    align-items: center;
    //text-transform: capitalize;
    cursor: pointer;
    width: 100%;
    overflow: hidden;

    &.first {
      display: flex;
      gap: 1rem;
    }
    .video-component {
      min-width: 4rem;
      min-height: 4rem;
      max-width: 4rem;
      max-height: 4rem;
    }
    .video-title {
      overflow: hidden;
      display: grid;
      grid-template-columns: 100%;
    }
  }

  .icon {
    cursor: pointer;
  }

  .videotitle {
    color: var(--body-text, #4c4c66);
    font-family: Nunito;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    letter-spacing: 0.1px;
    //text-transform: capitalize;
    width: 100%;
  }

  .title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .columns-text {
    overflow: hidden;
    color: var(--body-text, #4c4c66);
    text-overflow: ellipsis;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0.1px;
  }

  .date {
    font-size: 16px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0.10000000149011612px;
    text-align: left;
    color: #4c4c66;
  }

  .tags {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: 0px;
    text-align: center;
    color: #4c4c66;
    background: none;
    border-radius: 0;
    margin-right: 20px;
    padding: 0;
    gap: 8px;

    .tag {
      padding: 3px 20px;
      background-color: #f2f4fa;
      border-radius: 4px;
      margin: 0;
      display: flex;
      max-width: fit-content;
    }
  }
}

.exposure {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 3px 20px;
  width: 97px;
  height: 28px;
  border-radius: 4px;
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.1px;

  &.public {
    width: 115px;
    height: 28px;

    background: rgba(66, 200, 160, 0.1);
    border-radius: 4px;
    color: #42c8a0;
  }

  &.private {
    width: 115px;
    height: 28px;

    background: #f2f2f2;
    border-radius: 4px;

    color: #6f6e71;
  }
}

.status {
  width: 115px;
  height: 32px;

  background: #f2f2f2;
  border-radius: 4px;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  /* identical to box height */

  letter-spacing: 0.1px;
  text-transform: capitalize;

  /* dark gray */

  color: #6f6e71;
  display: flex;
  align-items: center;
  justify-content: center;

  &.active {
    color: #42c8a0;
    background: rgba(66, 200, 160, 0.1);
  }
}

.thumbnails {
  width: 100%;
  padding-bottom: 60%;
  border-radius: 6px 6px 0px 0px;
  background: #f2f4fa;

  img.image {
    border-radius: 6px 6px 0 0;
    width: 80px !important;
  }

  &.list {
    width: 80px;
    padding-right: auto;
    padding-bottom: 0;
    height: 53px;
    border-radius: 6px;
    margin-right: 32px;
    cursor: pointer;
  }
}
</style>
