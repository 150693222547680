import { ref, reactive, onMounted } from 'vue'
import { TRUVID_ENGLISH_LANGUAGE_ID } from '@/services/constants'
import { apiService } from '@/services'
import { Video } from '@/services/videos/types'

export function useVideoData(videoId: string) {
  const isLoading = ref(true)
  const error = ref<string | null>(null)
  const video = reactive<Video>({
    video_id: '',
    organization_name: '',
    organization_id: 0,
    folder_id: 0,
    title: '',
    description: '',
    upload_date: '',
    last_modified: '',
    duration_in_ms: 0,
    status: '',
    pricing_model: '',
    language_id: TRUVID_ENGLISH_LANGUAGE_ID,
    private: false,
    handle: '',
    formats: [],
    categories: [],
    thumb_ext: '',
    public_url: '',
    thumbnails: [],
    links: '',
    guid: '',
    clickthrough_urls: [{ id: 0, url: '', start_time: 0 }],
    ownership: false,
    tags: [],
    language_2_letters: '',
    thumbnail_url: '',
    country_allow: [],
    country_disallow: [],
    is_vertical: false
  })

  const relatedVideos = reactive([])

  const loadVideo = async (videoId: string) => {
    try {
      error.value = null
      const includeChannelData = true
      const vi = await apiService.videos.getVideo(videoId, includeChannelData)
      Object.assign(video, vi)
    } catch (err) {
      error.value = 'Failed to load video'
    }
  }

  const loadRelatedVideos = async (videoId: string) => {
    try {
      error.value = null
      const rvi = await apiService.videos.getRelatedVideos(videoId)
      Object.assign(relatedVideos, rvi)
    } catch (err) {
      error.value = 'Failed to load related videos'
    }
  }

  async function loadVideosAndRelatedVideos(videoId: string) {
    if (videoId) {
      try {
        isLoading.value = true
        error.value = null
        await Promise.all([loadRelatedVideos(videoId), loadVideo(videoId)])
      } catch (err) {
        error.value = 'An error occurred while loading data'
      } finally {
        isLoading.value = false
      }
    }
  }

  onMounted(async () => {
    await loadVideosAndRelatedVideos(videoId)
  })

  return { video, relatedVideos, isLoading, error, loadVideo, loadRelatedVideos, loadVideosAndRelatedVideos }
}
