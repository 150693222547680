<!-- eslint-disable vue/no-v-html-->
<template>
  <div class="messages">
    <transition-group name="fade" tag="p">
      <div v-for="message in store.state.messages.messages" :key="message.id" class="message" @click="closeMessage(message)">
        <template v-if="message.message">
          <IconWrapper v-if="message.type" :name="getIcon(message.type)" />
          <div class="text" v-html="message.message" />
        </template>
      </div>
    </transition-group>
  </div>
</template>

<script setup lang="ts">
import { onMounted } from 'vue'
import { useStore } from 'vuex'
import { IconWrapper } from '@/components/common'
import { Message } from '@/store/messages.module'

const store = useStore()

function closeMessage(message: Message) {
  store.commit('messages/removeMessage', message)
}

function getIcon(type: string) {
  if (type === 'fail' || type === 'error') {
    return 'failToastIcon'
  } else if (type === 'success') {
    return 'successToastIcon'
  } else if (type === 'warning') {
    return 'warningToastIcon'
  } else {
    return ''
  }
}

onMounted(() => {
  store.commit('messages/clearMessages')
})
</script>

<style scoped lang="scss">
@import '@/styles/_variables.scss';

.messages {
  position: fixed;
  bottom: 40px;
  right: 28px;
  z-index: $zIndex20000;
}

.message {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  padding: 1rem;
  gap: 0.5rem;
  margin: 10px;
  max-width: fit-content;
  text-align: center;
  position: relative;
  background: $titleBlack;
  box-shadow: rgba(67, 66, 89, 0.1) 10px 12.6 29px;
  border-radius: 30px;

  .text {
    font-weight: 700;
    font-size: 14px;
    line-height: 14px;
    letter-spacing: 0.1px;
    color: $white;
  }
}

.list-item {
  display: inline-block;
  margin-right: 10px;
}
.list-enter-active,
.list-leave-active {
  transition: all 41111111s;
}
.list-enter, .list-leave-to /* .list-leave-active below version 2.1.8 */ {
  opacity: 0;
  transform: translateY(30px);
}
.list-move {
  transition: transform 1s;
}
</style>
