const defaultProfileImages = import.meta.glob('@/assets/channel-defaults/default-profile-*.svg', { eager: true })
const defaultBackgroundImages = import.meta.glob('@/assets/channel-defaults/default-background-*.svg', { eager: true })

const PROFILE_IMAGE_STORAGE_KEY = 'channel-default-profile-image'
const BACKGROUND_IMAGE_STORAGE_KEY = 'channel-default-background-image'

export const useDefaultChannelProfileImages = () => {
  const getRandomProfileImage = () => {
    const imageKeys = Object.keys(defaultProfileImages)
    const randomIndex = Math.floor(Math.random() * imageKeys.length)
    return defaultProfileImages[imageKeys[randomIndex]] as Record<string, string>
  }

  const getDefaultProfileImage = (organizationId: number): string => {
    const userProfileKey = `${PROFILE_IMAGE_STORAGE_KEY}-${organizationId}`
    const storedImage = sessionStorage.getItem(userProfileKey)
    if (storedImage) return storedImage
    const randomProfileImage = getRandomProfileImage()
    sessionStorage.setItem(userProfileKey, randomProfileImage.default)
    return randomProfileImage.default
  }

  const getRandomBackgroundImage = () => {
    const imageKeys = Object.keys(defaultBackgroundImages)
    const randomIndex = Math.floor(Math.random() * imageKeys.length)
    return defaultBackgroundImages[imageKeys[randomIndex]] as Record<string, string>
  }

  const getDefaultBackgroundImage = (): string => {
    const storedImage = sessionStorage.getItem(BACKGROUND_IMAGE_STORAGE_KEY)
    if (storedImage) return storedImage

    const randomBackgroundImage = getRandomBackgroundImage()
    sessionStorage.setItem(BACKGROUND_IMAGE_STORAGE_KEY, randomBackgroundImage.default)
    return randomBackgroundImage.default
  }

  return { getDefaultProfileImage, getDefaultBackgroundImage }
}
