<template>
  <div v-if="props.viewType === DataViewType.Grid" class="content" @click="openEditPackage()">
    <div class="mask">
      <div class="package-top">
        <ImageComponent :source="getThumbnail()" class="thumbnail" :border-radius="[6, 6, 0, 0]" />
        <div class="row flex-between">
          <div class="package-info">
            <span class="name-no-capitalize">
              <TeleportTooltip placement="top" :text-length="20">
                <div>
                  {{ props.item.package_name }}
                </div>
                <template #content>
                  <div class="style-font">{{ props.item.package_name }}</div>
                </template>
              </TeleportTooltip>
            </span>
            <div v-if="props.item.description" class="description">
              <TeleportTooltip placement="bottom" :text-length="25">
                <div>
                  {{ props.item.description }}
                </div>
                <template #content>
                  <div class="style-font">{{ props.item.description }}</div>
                </template>
              </TeleportTooltip>
            </div>
            <div class="date">{{ getDateString(props.item.created_at) }}</div>
          </div>
          <ActionButtons :actions="actions" :view-type="DataViewType.Grid" />
        </div>
      </div>
    </div>
    <div class="package-bottom">
      <div class="package-counts">
        <div class="count">
          {{ props.item.total_videos }} <span class="count-title">{{ $t('content-total-videos') }}</span>
        </div>
        <div class="count">
          {{ props.item.total_folders }} <span class="count-title">{{ $t('content-total-folders') }}</span>
        </div>
        <div class="count">
          {{ props.item.publishers_count }} <span class="count-title">{{ $t('content-total-publishers') }}</span>
        </div>
      </div>
      <div class="categories">
        <VideoComponentCategories :categories="categories" class="categoriescomponent" />
      </div>
    </div>
  </div>

  <div
    v-if="props.viewType === DataViewType.List"
    id="package"
    ref="dataViewRow"
    class="package list"
    :style="`grid-template-columns:  ${columnsGridTemplate(props.columns)};`"
    @click="openEditPackage"
  >
    <div class="head" :class="shouldHideOnBreakpoint(props.columns, 'name')">
      <div class="title">
        <div>
          <PlaylistImage :source="getThumbnail()" class="thumbnails list" :border-radius="[3, 3, 3, 3]" />
        </div>

        <div class="title-text">
          <Tooltip :placement="'top'" :content="props.item.package_name" auto-ellipsis />
        </div>
      </div>
    </div>

    <div class="head" :class="shouldHideOnBreakpoint(props.columns, 'created_at')">
      <div class="columns-text">{{ getDateString(props.item.created_at) }}</div>
    </div>
    <div class="head" :class="shouldHideOnBreakpoint(props.columns, 'total_folders')">
      <div class="videos-amount">
        <div class="videos-amount-number">
          {{ props.item.total_folders }}
        </div>
      </div>
    </div>
    <div class="head" :class="shouldHideOnBreakpoint(props.columns, 'total_videos')">
      <div class="videos-amount">
        <div class="videos-amount-number">
          {{ props.item.total_videos }}
        </div>
      </div>
    </div>
    <div class="head" :class="shouldHideOnBreakpoint(props.columns, 'publishers_count')">
      <div class="videos-amount">
        <div class="videos-amount-number">
          {{ props.item.publishers_count }}
        </div>
      </div>
    </div>
    <ActionButtons :actions="actions" :is-visible="isRowHovered" :class="shouldHideOnBreakpoint(props.columns, 'actions')" />
  </div>
</template>

<script setup lang="ts">
import PlaylistImage from '@/components/content/playlist/PlaylistImage.vue'
import { getDateString, openModalCustomConfirmation } from '@/services/utils'
import { useRouter } from 'vue-router'
import { CRUD, DataActions, DataType, DataViewType } from '@/interfaces'
import ActionButtons from '@/components/common/action-buttons/ActionButtons.vue'
import { computed, inject, ref } from 'vue'
import { columnsGridTemplate, shouldHideOnBreakpoint } from '@/services/layoutUtils'
import { useElementHover } from '@vueuse/core'
import VideoComponentCategories from '@/components/base/videoComponent/VideoComponentCategories.vue'
import ImageComponent from '@/components/common/ImageComponent.vue'
import addVideosIconPlaceholder from '@/assets/playlists/playlist/add-videos-icon-placeholder.svg'
import { TeleportTooltip, Tooltip } from '@/components/common'
import { useStore } from 'vuex'
import { MY_PACKAGES_ACTIONS_INJECTION_KEY } from '@/constants'
import { ContentPackage } from '@/services/content/package/types'
import { apiService } from '@/services'
import { useI18n } from 'vue-i18n'
import { ModalType } from '@/components/modals/modals-util'

defineEmits(['refreshRow'])
const image = new window.Image()
image.src = addVideosIconPlaceholder
const store = useStore()
const props = defineProps(['item', 'viewType', 'columns', 'detailsLink'])
const router = useRouter()
const { t } = useI18n()
const dataViewRow = ref()
const isRowHovered = useElementHover(dataViewRow)
const isEmptyPackage = computed(() => props.item.total_folders === 0 && props.item.total_videos === 0)
const actions = computed(() => [
  {
    label: 'distribute',
    handler: openSharePackage,
    translationKey: 'content-package-share'
  },
  {
    label: 'edit',
    handler: openEditPackage,
    translationKey: 'content-package-edit'
  },
  {
    label: 'duplicate',
    handler: openDuplicatedPackage,
    translationKey: 'content-package-duplicate',
    isDisabledWithTooltip: isEmptyPackage.value ? 'content-package-duplicate-empty-tooltip' : ''
  }
])
const categories = computed(() => {
  let sumCategories = []
  props.item.first_videos?.forEach((video) => {
    if (video.categories) {
      sumCategories = [...sumCategories, ...video.categories]
    }
  })
  return [...new Set(sumCategories)]
})
const modalType = ModalType.PACKAGE_DUPLICATE_MODAL
const dataActions: DataActions | undefined = inject<DataActions>(MY_PACKAGES_ACTIONS_INJECTION_KEY)
const onDuplicatePackage = async ({ inputValue }: { inputValue: string }) => {
  const item = await apiService.contentPackage.getContentPackage({ ...props.item }.package_id)
  const duplicatePackage: ContentPackage = {
    ...item,
    package_name: inputValue
  }
  return apiService.contentPackage
    .postPackage(duplicatePackage.package_name, duplicatePackage.description, duplicatePackage.video_ids, duplicatePackage.folder_ids, duplicatePackage.tags)
    .then(() => {
      store.dispatch('messages/addMessage', { message: t('content-package-duplicate-message-success'), type: 'success' })
      if (dataActions?.clearSearch) dataActions.clearSearch()
      if (dataActions?.resetToDefaultPageAndGetData) dataActions.resetToDefaultPageAndGetData()
    })
    .catch((error) => {
      store.dispatch('messages/addMessage', { message: error.response.data.error.description, type: 'success' })
    })
}

function openEditPackage() {
  router.push({
    path: `/packages/edit/${props.item.package_id}`,
    name: 'edit-package',
    params: { id: props.item.package_id }
  })
}

function openSharePackage() {
  router.push({
    path: '/packages/edit',
    name: 'edit-package',
    params: { id: props.item.package_id },
    query: { state: 'share' }
  })
}

function openDuplicatedPackage() {
  openModalCustomConfirmation(
    {
      action: CRUD.Duplicate,
      type: DataType.Package,
      itemName: props.item.package_name,
      id: props.item.package_id,
      onConfirm: onDuplicatePackage
    },
    modalType
  )
}

function getThumbnail() {
  const video = props.item.first_videos?.[0]
  if (!video || !video.thumbnails?.length) {
    return image.src
  }
  const index = video.is_vertical && video.thumbnails[3] ? 3 : 0
  return video.thumbnails[index]?.url || image.src
}
</script>

<style scoped lang="scss">
.nowrap {
  white-space: nowrap !important;
}

.content {
  display: flex;
  flex-direction: column;
  cursor: pointer;
  border-radius: 6px;
  @include hoverFrameEffect;
  .thumbnail {
    position: absolute;
    overflow: hidden;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
  }
  .package-info {
    display: flex;
    flex-direction: column;

    .name {
      color: var(--white, #fff);
      /* H3 */
      font-family: Nunito;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      //text-transform: capitalize;
    }
    * {
      white-space: nowrap;
    }

    .name-no-capitalize {
      color: var(--white, #fff);
      /* H3 */
      font-family: Nunito;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }

    .description {
      color: var(--white, #fff);
      /* P */
      font-family: Nunito;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      letter-spacing: 0.1px;
    }

    .date {
      color: var(--white, #fff);
      /* Small */
      font-family: Nunito;
      font-size: 12px;
      font-style: normal;
      font-weight: 300;
      line-height: normal;
      text-transform: capitalize;
      margin-top: 4px;
    }
  }
}

.disabled {
  opacity: 0.2;
  pointer-events: none;
}

.no-folders {
  opacity: 0.7;
}

.package {
  width: 100%;
  height: fit-content;
  cursor: pointer;

  .head {
    padding-left: 1rem;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    display: grid;
    grid-template-columns: 100%;
    overflow: hidden;
  }

  &.list {
    width: 100%;
    display: grid;
    align-items: center;

    &:hover {
      background-color: #f2f4fa;
    }

    .icon {
      cursor: pointer;
    }

    .title {
      display: flex;
      align-items: center;
      gap: 1rem;

      color: #4c4c66;

      .title-text {
        display: grid;
        grid-template-columns: 100%;
        overflow: hidden;
        color: var(--body-text, #4c4c66);
        text-overflow: ellipsis;
        white-space: nowrap;
        font-family: Nunito;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: 0.1px;
      }
    }

    .columns-text {
      overflow: hidden;
      color: var(--body-text, #4c4c66);
      text-overflow: ellipsis;
      /* P */
      font-family: Nunito;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      letter-spacing: 0.1px;
      text-transform: capitalize;
    }

    .videos-amount-number {
      display: flex;
      width: 22px;
      height: 22px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      border-radius: 30px;
      background: #dadbe8;
      border: 1px solid $inputBorder;
      border-radius: 30px;
      color: #4c4c66;
      text-align: center;
      font-family: Nunito;
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      text-transform: capitalize;
      margin-right: 8px;
    }

    .videos-amount {
      padding-left: 5px;
      /* white */
      display: flex;
      align-items: center;
      justify-content: flex-start;
      font-family: Nunito;
      font-size: 16px;
      font-weight: 600;
      line-height: 22px;
      letter-spacing: 0.10000000149011612px;
      text-align: left;
      color: #4c4c66;
    }

    .date {
      font-family: Nunito;
      font-size: 16px;
      font-weight: 600;
      line-height: 22px;
      letter-spacing: 0.10000000149011612px;
      text-align: left;
      color: #4c4c66;
    }

    .tags {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      font-family: Nunito;
      font-size: 12px;
      font-weight: 600;
      line-height: 16px;
      letter-spacing: 0px;
      text-align: center;
      color: #4c4c66;
      background: none;
      border-radius: 0;
      margin-right: 20px;
      padding: 0;
      gap: 8px;

      .videos-amount-number {
        display: flex;
        width: 27px;
        height: 26px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 30px;
        background: var(--title-2, #7d81b2);
        border: 1px solid #7d81b2;
        border-radius: 30px;
        color: var(--white, #fff);
        text-align: center;
        /* Small bold */
        font-family: Nunito;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        text-transform: capitalize;
      }

      .tag {
        padding: 3px 20px;
        background-color: #f2f4fa;
        border-radius: 4px;
        margin: 0;
        display: flex;
        max-width: fit-content;
      }
    }
  }

  .frame {
    //padding: 0% 0% 2% 0%;
    //margin-bottom: 20px;
    border-radius: 0 0 6px 6px;

    .view {
      cursor: pointer;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 3.81739px;
      font-family: 'Nunito';
      font-style: normal;
      font-weight: 700;
      font-size: 11px;
      line-height: 15px;
      /* identical to box height */

      text-transform: uppercase;

      color: #ffffff;
      position: absolute;
      width: 87px;
      height: 30px;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      opacity: 0;
      transition: opacity 0.3s;
    }

    .date {
      cursor: pointer;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;

      position: absolute;
      width: 87px;
      height: 30px;
      bottom: 0px;
      left: 15px;

      font-style: normal;
      font-weight: 300;
      font-size: 14px;
      line-height: 19px;
      letter-spacing: 0.1px;
      text-transform: capitalize;
      color: #ffffff;

      img {
        margin-right: 4px;
      }
    }
  }
}

.thumbnails {
  width: 100%;
  padding-bottom: 60%;
  border-radius: 6px 6px 0 0;
  background: #f2f4fa;

  img.image {
    border-radius: 6px 6px 0 0;
  }

  &:hover {
    .view {
      opacity: 1;
    }
  }

  .info {
    top: 15px;
    left: 15px;
    position: absolute;

    .title {
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 25px;
      text-transform: capitalize;
      color: #ffffff;
      margin-bottom: 2px;
    }

    .params {
      font-style: normal;
      font-weight: 300;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 0.1px;
      text-transform: capitalize;
      color: #ffffff;
      display: flex;
      justify-content: left;
      align-items: center;

      .dot {
        display: flex;
        width: 19px;
        justify-content: center;
        align-items: center;
      }
    }
  }

  &.list {
    width: 4.5rem;
    height: 4.5rem;
    padding-bottom: 0;
    border-radius: 6px;
    margin-right: 1rem;
    cursor: pointer;
  }
}

.row {
  display: flex;
  min-height: 60px;
  max-height: 60px;
  align-items: center;
}

.flex-between {
  justify-content: space-between;
  align-items: flex-start;
  position: relative;
}

.title {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: #7d81b3;
  margin: 16px 0;
}

.categories {
  width: 90%;
  max-width: 90%;
  padding-left: 12px;
  margin-top: 8px;
  height: 32px;
  .categoriescomponent {
    position: absolute;
    width: 90%;
  }
}

.package-top {
  background: #f2f4fa;
  border-radius: 6px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  padding: 20px 20px 0 20px;
  margin: 0;
  aspect-ratio: 16/9;
  height: auto;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.package-bottom {
  border: 1px solid $inputBorder;
  border-radius: 0 0 6px 6px;
  border-top: 0;
  width: 100%;
  position: relative;

  .package-counts {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    margin: 0;
    padding: 0;
    max-width: 100%;
    height: 70px;

    .count {
      display: flex;
      flex-direction: column;
      font-family: Nunito;
      font-size: 24px;
      font-style: normal;
      font-weight: 800;
      line-height: normal;
      letter-spacing: 0.3px;
      text-transform: capitalize;
      justify-content: center;
      text-align: center;
      margin: 0;
      place-self: center stretch;
      position: relative;

      .count-title {
        color: var(--body-text, #4c4c66);
        /* P */
        font-family: Nunito;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: 0.1px;
        text-transform: capitalize;
      }
    }
    :not(:last-child) {
      &.count::after {
        content: '';
        background: #dadbe8;
        position: absolute;
        bottom: 15%;
        right: 0;
        height: 70%;
        width: 1px;
      }
    }
  }
}

.folders {
  height: 38px;
  width: 100%;
  overflow: hidden;
  .item {
    border-radius: 2px;
    margin-right: 8px;
    padding: 3px 13px;
    border-radius: 4px;
    border: 1px solid var(--title-3, #dadbe8);
    background: var(--title-3, #dadbe8);
    color: var(--body-text, #4c4c66);
    text-align: center;
    /* P */
    font-family: Nunito;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.1px;
    text-transform: capitalize;
  }

  .item-no-capitalize {
    margin-right: 8px;
    padding: 3px 13px;
    border-radius: 4px;
    border: 1px solid var(--title-3, #dadbe8);
    background: var(--title-3, #dadbe8);
    color: var(--body-text, #4c4c66);
    text-align: center;
    /* P */
    font-family: Nunito;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.1px;
    text-transform: none !important;
  }

  .additionalFolders {
    display: flex;
    width: 27px;
    height: 26px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 30px;
    background: var(--title-2, #7d81b2);
    border: 1px solid #7d81b2;
    color: var(--white, #fff);
    text-align: center;
    /* Small bold */
    font-family: Nunito;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: capitalize;
  }
}

.listFolders {
  display: flex;
  align-items: center;

  .item {
    margin-right: 8px;
    padding: 3px 13px;
    border-radius: 4px;
    border: 1px solid var(--title-3, #dadbe8);
    background: var(--title-3, #dadbe8);
    color: #4c4c66;
    text-align: center;
    font-family: Nunito, serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.1px;
    text-transform: capitalize;
  }

  .additionalFolders {
    display: flex;
    width: 27px;
    height: 26px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 30px;
    background: var(--title-2, #7d81b2);
    color: var(--white, #fff);
    text-align: center;
    /* Small bold */
    font-family: Nunito;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: capitalize;
  }
}

// .flex {
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   align-content: center;
// }
</style>
