<template>
  <Tag
    :label="localizedOrientationLabel" :icon="orientationImg" :show-close="false" :show-img="showImg"
    :tag-colors="tagColors" :size="size"
  />
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { Orientation } from '@/interfaces'
import Tag from '@/components/common/tag/Tag.vue'
import { TagSizes, TagColors } from './tag/utils'

const { t } = useI18n()

const props = defineProps({
  isVertical: {
    type: Boolean,
    required: true,
    default: false
  },
  showImg: {
    type: Boolean,
    default: true
  },
  tagColors: {
    type: String as () => TagColors,
    default: TagColors.Black
  },
  size: {
    type: String as () => TagSizes,
    default: TagSizes.Small
  }
})

const localizedOrientationLabel = computed(() => t(props.isVertical ? Orientation.Vertical : Orientation.Horizontal))
const orientationImg = computed(() => (props.isVertical ? 'rotationVertical' : 'rotationHorizontal'))
</script>
