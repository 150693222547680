<template>
  <div class="branoPlayerWrapper">
    <div id="branoPlayer">
      <LoadingSpinner v-if="isUploading" class="spinner" />
      <LoadingSpinner v-else-if="!store.state.user.playerLoaded" class="spinner" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { apiService } from '@/services'
import store from '@/store'
import { onMounted, onUnmounted, ref, computed, watch, onBeforeUnmount } from 'vue'
import { LoadingSpinner } from '@/components/common'
import { uid } from 'uid'
import { VIDEO_MIME_TYPES, VIDEO_RESOLUTION } from '@/services/constants'

const emit = defineEmits(['setSideContainerOnFocus'])
const props = defineProps(['video', 'updatedThumbnail', 'thumbnailUploading'])
const playerStarted = ref(false)
const isUploading = ref(false)
const isPlayerDestroyed = computed(() => !document.querySelector('.trv-player-container'))
const videoThumb = ref(props.updatedThumbnail)

function handleFullscreenChange() {
  const isFullscreen = !!document.fullscreenElement
  if (!isFullscreen) {
    emit('setSideContainerOnFocus')
  }
}

async function closePlayer() {
  while (!playerStarted.value) {
    await new Promise((resolve) => setTimeout(resolve, 300))
  }
  if (playerStarted.value) {
    if (window.player) {
      window.player.DestroyPlayer()
    }
  }
}

watch(
  () => props.updatedThumbnail,
  () => {
    videoThumb.value = props.updatedThumbnail
    window.player.DestroyPlayer()
    play()
  }
)

watch(
  () => props.thumbnailUploading,
  () => {
    isUploading.value = props.thumbnailUploading
  }
)

async function play() {
  if (!window.BranovatePlayer) {
    store.commit('user/setPlayerLoaded', false)
    const isLoaded = await apiService.general.getVideoPlayer()
    store.commit('user/setPlayerLoaded', isLoaded)
  }
  const playbackFormat = getVideoPlaybackFormat()
  if (playbackFormat?.path_in_cdn && props.video.thumbnails?.length > 0) {
    if (window.player && !isPlayerDestroyed.value) {
      window.player.DestroyPlayer()
    }

    while (!store.state.user.playerLoaded) {
      await new Promise((resolve) => setTimeout(resolve, 500))
    }
    window.player = new window.BranovatePlayer()
    const posterElement = document.querySelector('.trv-poster')
    if (posterElement) {
      posterElement.style.backgroundSize = 'cover'
    }
    const formats = [
      {
        h: playbackFormat.height,
        l: playbackFormat.label,
        c: VIDEO_MIME_TYPES.MP4,
        p: playbackFormat.path_in_cdn,
        f: playbackFormat.format
      }
    ]

    const playlistContent = [
      {
        dur: Math.ceil(props.video.duration_in_ms / 1000),
        d: '',
        t: '',
        f: formats,
        p: videoThumb.value ? videoThumb.value : props.video.thumbnails[0]?.url + '?' + uid(),
        lnks: props.video.links ? JSON.parse(props.video.links) : []
      }
    ]
    const screenWidth = 640
    const screenHeight = 360
    if (document.getElementById('branoPlayer')) {
      await window.player.setup({
        elemId: '#branoPlayer',
        width: screenWidth,
        height: screenHeight,
        isResponsive: true,
        repeat: false,
        playlistContent: playlistContent,
        autoplay: false,
        isFullscreen: true
      })
      playerStarted.value = true
      window.player.switchToVideoIndex(0)
    }
  }
}

onMounted(() => {
  document.addEventListener('fullscreenchange', handleFullscreenChange)
  if (props.video) {
    play()
  } else {
    closePlayer()
  }
})

onBeforeUnmount(() => {
  document.removeEventListener('fullscreenchange', handleFullscreenChange)
})

onUnmounted(() => {
  closePlayer()
})

function getVideoPlaybackFormat() {
  return props.video.formats.find((format) => {
    return format.label.includes(VIDEO_RESOLUTION.SD.height) && format.format === 'mpeg4'
  })
}
</script>

<style scoped lang="scss">
@import '@/styles/_variables.scss';

.branoPlayerWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
}

#branoPlayer {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(0, 0, 0);
  .spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
.close {
  position: absolute;
  right: 0px;
  z-index: $zIndex10;
  margin: 10px 10px 0 0;
  cursor: pointer;
}
.info {
  background-color: #fff;
  width: 640px;
  height: 100px;
  border-radius: 0 0 10px 10px;
  padding: 20px 10px 10px 30px;
  .title {
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 800;
    font-size: 21px;
    line-height: 29px;
    text-transform: capitalize;
    height: 30px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: 95%;
    /* body text */

    color: #4c4c66;
  }
  .desc {
    margin-top: 7px;
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    /* identical to box height */

    letter-spacing: 0.1px;
    text-transform: capitalize;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: 95%;

    /* gray */

    color: #8a898c;
  }
}

:deep(.media-video) {
  border-radius: 10px !important;
}
</style>
