<template>
  <div>
    <div class="ci-container textarea">
      <textarea
        :id="customId"
        :value="modelValue"
        :class="{ error: !valid, nofocus: disabled }"
        type="textarea"
        :placeholder="placeholder"
        :rows="rowCount"
        autocomplete="off"
        @input="handleEvents"
        @blur="validate"
      />
    </div>
    <span v-if="showCharacterCount" class="character-counter" :class="{ 'is-visible': isCharacterCountVisible }">
      {{ $t('character_count', { count: modelValue?.length, limit: showCharacterCount }) }}
    </span>
  </div>
</template>

<script setup lang="ts">
import { ref, computed } from 'vue'
import { uid } from 'uid'

const customId = ref(uid())
const props = defineProps<{
  placeholder?: string
  label?: string
  valid?: boolean
  modelValue: string | null
  rowCount?: number | undefined
  disabled?: boolean | null
  showCharacterCount?: number
}>()

const isCharacterCountVisible = computed(() => {
  return props.showCharacterCount && props.modelValue && props.modelValue?.length < props.showCharacterCount
})

const emit = defineEmits<{
  (e: 'update:modelValue', value: number | string | object | null): void
  (e: 'iconActionEmit', value: string): void
  (e: 'validate'): void
}>()

function handleEvents(event: Event) {
  emit('validate')
  const target = event.target as HTMLTextAreaElement
  emit('update:modelValue', target.value)
}

function validate() {
  emit('validate')
}
</script>

<style scoped lang="scss">
.ci-container {
  position: relative;
  textarea {
    display: block;
  }
}

.nofocus {
  resize: none;
  pointer-events: none;
  input:focus {
    outline: none;
  }
}

.character-counter {
  color: #6f6e71;
  font-size: 12px;
  visibility: hidden;
  position: absolute;
  margin-top: 5px;
  &.is-visible {
    visibility: visible;
  }
}
</style>
