import { apiService } from '@/services'
import type { Video } from '@/services/videos/types'
import { Playlist } from '@/services/playlist/types'
import { TRUVID_CREATE_PLAYLIST_STATES } from '@/services/constants'

export interface Playlists {
  playlists: Playlist[]
  selectedPlaylists: Playlist[]
  selectedPlaylist: Playlist
  edit: boolean
  total: number
  currentPageNumber: number
  selectedVideos: Video[]
  creatingNewPlaylist: boolean
  createPlaylistStep: number
  isPlaylistFromLibraryFilters: boolean
}

export const playlists = {
  namespaced: true,
  state: () => ({
    playlists: [],
    selectedPlaylists: [],
    selectedPlaylist: null,
    edit: false,
    totalPlaylists: 0,
    currentPageNumber: 1,
    selectedVideos: [],
    creatingNewPlaylist: false,
    createPlaylistStep: TRUVID_CREATE_PLAYLIST_STATES.ADD_VIDEOS,
    isPlaylistFromLibraryFilters: false
  }),
  getters: {
    getIsCreatingNewPlaylist: (state) => () => {
      return state.creatingNewPlaylist
    },
    getCreatePlaylistStep: (state) => () => {
      return state.createPlaylistStep
    },
    getIsPlaylistFromLibraryFilters: (state) => () => {
      return state.isPlaylistFromLibraryFilters
    }
  },
  mutations: {
    setPlaylists(state, playlists: Playlist[]) {
      state.playlists = playlists
    },
    setTotal(state, total: number) {
      state.totalPlaylists = total
    },
    setSelectedPlaylists(state, selectedPlaylists: Playlist[]) {
      state.selectedPlaylists = selectedPlaylists
    },
    setSelectedPlaylist(state, selectedPlaylist: Playlist) {
      state.selectedPlaylist = selectedPlaylist
    },
    setIsPlaylistFromLibraryFilters(state, isPlaylistFromLibraryFilters: boolean) {
      state.isPlaylistFromLibraryFilters = isPlaylistFromLibraryFilters
    },
    setEditable(state, edit: boolean) {
      state.edit = edit
    },
    setCreatingNewPlaylist(state, creating: boolean) {
      state.creatingNewPlaylist = creating
    },
    setSelectedVideos(state, selectedVideos: Video[]) {
      state.selectedVideos = selectedVideos
    },
    selectVideo(state, selectedVideo: Video) {
      state.selectedVideos.push(selectedVideo)
    },
    selectVideoOnMounted(state, selectedVideo: Video) {
      state.selectedVideos.push(selectedVideo)
    },
    deselectPlaylist(state) {
      state.selectedPlaylist = null
    },
    deselectAllVideos(state) {
      state.selectedVideos = []
    },
    deselectVideo(state, id) {
      state.selectedVideos = state.selectedVideos.filter((item) => item.video_id !== id)
    },
    deselectAllPlaylists(state) {
      state.selectedPlaylists = []
    },
    setCurrentPageNumber(state, page: number) {
      state.currentPageNumber = page
    },
    setCreatePlaylistStep(state, step) {
      state.createPlaylistStep = step
    },
    resetPlaylistState(state) {
      state.playlists = []
      state.selectedPlaylists = []
      state.selectedPlaylist = null
      state.edit = false
      state.totalPlaylists = 0
      state.currentPageNumber = 1
      state.selectedVideos = []
      state.createPlaylistStep = TRUVID_CREATE_PLAYLIST_STATES.ADD_VIDEOS
      state.creatingNewPlaylist = false
      state.isPlaylistFromLibraryFilters = false
    }
  },
  actions: {
    async updatePlaylists(context, filters) {
      const pls = await apiService.playlist.getPlaylists(filters)
      context.commit('setPlaylists', pls.playlists)
      context.commit('setTotal', pls.total)
    },
    reset(context) {
      context.commit('setTotal', 0)
      context.commit('setPlaylists', [])
    },
    clearPlaylist(context) {
      context.commit('resetPlaylistState')
    }
  }
}
