import i18n from '@/i18n'
import router from '@/router'
import { apiService } from '@/services'
import { HTTP_STATUS_BAD_REQUEST, HTTP_STATUS_OK } from '@/services/constants'
import { UserRolesNames, UserSection } from '@/services/organization-user/constants'
import store from '@/store'
import { deleteCookiesWithPrefix } from './utils'
import orgAuthLogin from '@/services/org-auth-login'
import { LoginUser } from '@/services/organization-user/types'

export default {
  async login(user: { email: string; password: string }): Promise<{ status: number; message: string }> {
    try {
      const userData = await apiService.organizationUser.login(user)

      // Check if the user already exists in the store
      let userIndex = store.state.user.users.findIndex((u) => u.user.email === user.email)
      if (userIndex === -1) {
        // User doesn't exist, so add them as a new user
        await store.dispatch('user/addNewUser', userData)
        userIndex = store.state.user.users.length - 1 // Index of the newly added user
      } else {
        // User exists, so update their token
        await store.dispatch('user/setAccessToken', { index: userIndex, userData })
      }

      await this.setUserData(userData)
      return { status: HTTP_STATUS_OK, message: i18n.global.t('ok') }
    } catch (error) {
      return { status: HTTP_STATUS_BAD_REQUEST, message: i18n.global.t('login-error') }
    }
  },

  async loginFromAuth(organizationId: number, userId: number, token: string) {
    try {
      const userData = await orgAuthLogin(Number(organizationId), Number(userId), token)
      await store.dispatch('user/addNewUser', userData)
      await store.dispatch('user/extendOrgData')
      store.commit('user/setIsLoginFromAuth', true)
      await this.setUserData(userData)
      return { status: HTTP_STATUS_OK, message: i18n.global.t('ok') }
    } catch (error) {
      return { status: HTTP_STATUS_BAD_REQUEST, message: i18n.global.t('login-error') }
    }
  },

  async setUserData(userData: LoginUser) {
    // Set the active user to be the user who just logged in
    store.commit('setAuthenticated', true)
    if (userData.roles[0].name === UserRolesNames.AgencyOwner) {
      //Set is agency so we can set the correct permissions and tokens
      store.commit('user/setIsRootUserAgency', true)
      //Set user mode, we need this to handle UI
      store.commit('user/setMode', UserSection.Agency)
    }
    store.commit('todoList/setState', await apiService.organizationUser.getUserTodoList())
  },

  // async switchToRemoteAccount(id): Promise<{  status: number, message: string}> {
  async switchToRemoteAccount(orgId: number, userId: number): Promise<{ status: number; message: string }> {
    try {
      // Check if the user already exists in the store
      let userIndex = store.state.user.users.findIndex((u) => u.user.id === userId)

      if (userIndex === -1) {
        // User doesn't exist, so fetch their data from the API
        // const userData = await apiService.login(user);
        const userData = await apiService.organization.loginRemoteAccount(orgId, userId)

        // Add the new user to the store
        await store.dispatch('user/addNewUser', userData)
        userIndex = store.state.user.users.length - 1 // Index of the newly added user
      }

      // Set the active user to be the user who just logged in
      await store.dispatch('user/setActiveUser', userIndex)
      store.commit('setAuthenticated', true)

      store.commit('todoList/setState', await apiService.organizationUser.getUserTodoList())
      //Set user mode, we need this to handle UI
      store.commit('user/setMode', 'user')

      router.push('/')

      return { status: 200, message: 'OK' }
    } catch (error) {
      return { status: 400, message: 'Error' }
    }
  },

  async getUserInfo(userId: number) {
    try {
      const userInfo = await apiService.organizationUser.getUserInfo(userId)
      store.commit('user/setUserInfo', userInfo)
      return userInfo
    } catch (error) {
      return { status: 400, message: 'The email or password is incorrect' }
    }
  },

  async logout(routeToLogin = true) {
    if (routeToLogin === true) {
      await router.push('/login')
    }
    store.dispatch('cache/setIsUserLoggedIn', false)
    store.commit('setAuthenticated', false)
    // store.commit('setRedirectRoute', '')
    store.commit('user/logout')
    store.commit('folders/logout')
    store.commit('domains/logout')
    store.commit('reports/logout')
    store.commit('dashboard/logout')
    store.commit('library/logout')
    store.commit('packages/logout')
    store.commit('playlists/resetPlaylistState')
    store.commit('settings/logout')
    store.commit('widget/unsetWidget')
    store.commit('widgets/logout')
    store.commit('cache/clearCachedRoles')
    store.commit('todoList/logout')
    store.commit('modal/closeModal')
    deleteCookiesWithPrefix('infobar')
    if (import.meta.env.VITE_APP_IS_PRODUCTION === 'true') {
      heap.resetIdentity()
    }
    // store.commit('cache/logout')
  }
}
