<template>
  <Button
    :variant="variant" :text="text" :size="size" :disabled="disabled"
    :disable-hover="disableHover" @click="emit('click')"
  >
    <div class="content-container" :class="{ 'icon-right': isIconRight }">
      <LoadingSpinner v-if="isLoading" class="spinner" :size="'mini'" :is-mini-white="variant === ButtonVariant.PRIMARY" />
      <img v-if="iconCmp && !isLoading" :src="iconCmp">
      <IconWrapper v-else-if="iconName && !isLoading" :name="iconName" />
      <slot>{{ text }}</slot>
    </div>
  </Button>
</template>

<script setup lang="ts">
import { ButtonVariant, ButtonSize, WithIconButton } from '@/styles/types'
import { LoadingSpinner, Button, IconWrapper } from '@/components/common'

// SVG files can be imported in two ways:

// Option A - Import the SVG file directly as a component:
// Example: import EmbedIcon from "@/assets/common/embed-icon.svg"
// Then pass EmbedIcon to the icon component (iconCmp).

// Option B (Preferred option) - Use the icon name with the WrapperIcon component:
// Pass the icon name from the `src\components\common\icons\index.ts` file to `WrapperIcon`
// This approach allows you to manage icons more dynamically and consistently.

withDefaults(defineProps<WithIconButton>(), {
  variant: ButtonVariant.PRIMARY,
  size: ButtonSize.BIG,
  disabled: false,
  iconCmp: '',
  iconName: '',
  isIconRight: false,
  isLoading: false
})
const emit = defineEmits(['click'])
</script>

<style scoped lang="scss">
.content-container {
  display: flex;
  align-items: center;
  gap: 0.5rem;

  &.icon-right {
    flex-direction: row-reverse;
  }

  .spinner {
    width: 1rem;
    height: 1rem;
  }
}
</style>
