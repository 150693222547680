<template>
  <Transition name="fade">
    <div class="mobilemenubkg" @click="close" />
  </Transition>
  <Transition name="fly">
    <div class="mobilemenu" :class="{ 'with-scrollbar': withScrollbar }" @click.stop>
      <div class="top">
        <div class="back">
          <img v-if="props.showBack" src="@/assets/mobile/mobileMenuContainer/Back.svg" @click="emit('back')">
        </div>
        <div class="title">
          <span v-if="props.title">{{ $t(props.title) }}</span>
        </div>
        <div class="back">
          <img v-if="props.showClose" src="@/assets/mobile/mobileMenuContainer/Exit.svg" @click="emit('close')">
        </div>
      </div>
      <div class="top-additional">
        <slot name="top" />
      </div>
      <div class="content">
        <slot />
      </div>
      <footer class="footer">
        <slot name="footer" />
      </footer>
    </div>
  </Transition>
</template>

<script lang="ts" setup>
const emit = defineEmits(['close', 'back'])
const props = defineProps<{ showClose?: boolean; showBack?: boolean; title?: string; withScrollbar?: boolean }>()

function close() {
  emit('close')
}
</script>

<style lang="scss" scoped>
@import '@/styles/_variables.scss';

.mobilemenubkg {
  pointer-events: all;
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(2px);
  z-index: $zIndex10000;
}

.mobilemenu {
  pointer-events: all;
  position: fixed;
  background-color: white;
  border-radius: 16px 16px 0px 0px;
  max-height: calc(92% - 24px);
  width: 100vw;
  left: 0;
  z-index: $zIndex10000;
  display: flex;
  flex-direction: column;
  padding-top: 16px;
  bottom: 0;

  .top {
    width: 100%;
    display: grid;
    grid-template-columns: 48px auto 48px;
    padding: 8px 19px 0 24px;
    justify-items: center;
    align-items: center;

    img {
      cursor: pointer;
    }
  }

  .top-additional {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
  }
}

.title {
  white-space: nowrap;
  font-size: 18px;
  font-weight: 700;
}

.fade-enter-active,
.fade-leave-active {
  transition: all 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.fly-enter-active,
.fly-leave-active {
  transition: all 0.5s ease;
}

.fly-enter-from,
.fly-leave-to {
  transform: translateY(100%);
}
</style>
