<template>
  <div class="image_wrapper">
    <img
      loading="lazy"
      class="image original"
      :class="{ loaded: loaded }"
      :src="currentSource"
      alt="random image"
      :title="blank ? $t('playlist-create-add-more-videos') : ''"
      :style="{ borderRadius: `${borderRadius[0]}px ${borderRadius[1]}px ${borderRadius[2]}px ${borderRadius[3]}px` }"
      @load="onImgLoad"
      @click="addVideos"
    >
    <div
      v-if="!(masks === 'false' || blank)"
      :class="!miniImage ? 'top-mask' : ''"
      :style="{ borderRadius: `${borderRadius[0]}px ${borderRadius[1]}px ${borderRadius[2]}px ${borderRadius[3]}px` }"
    />
    <div
      v-if="!(masks === 'false' || blank)"
      :class="!miniImage ? 'bottom-mask' : ''"
      :style="{ borderRadius: `${borderRadius[0]}px ${borderRadius[1]}px ${borderRadius[2]}px ${borderRadius[3]}px` }"
    />
    <slot />
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { onMounted, ref, watch } from 'vue'
const props = defineProps(['source', 'masks', 'blank', 'miniImage', 'borderRadius'])
const emit = defineEmits(['add', 'edit'])
const currentSource = ref('')
const loaded = ref(false)
const currentBorderRadius = ref([])

onMounted(() => {
  loaded.value = false
  currentSource.value = props.source
  currentBorderRadius.value = props.borderRadius
})

function onImgLoad() {
  loaded.value = true
}

function addVideos() {
  props.blank ? emit('add') : emit('edit')
}

const computedSource = computed({
  get: () => {
    return props.source
  },
  set: (val) => {
    currentSource.value = val
  }
})
watch(computedSource, () => {
  currentSource.value = computedSource.value
})
</script>

<style lang="scss">
.image_wrapper {
  position: relative;
}
.top-mask {
  width: 100%;
  height: 40%;
  position: absolute;
  top: 0px;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0) 100%);
}
.bottom-mask {
  width: 100%;
  height: 50%;
  position: absolute;
  bottom: 0px;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0) 100%);
}

.image {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  transition: opacity 1s ease-out;
  object-fit: cover;

  &.loaded {
    opacity: 1;
  }
}
</style>
