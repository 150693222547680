<template>
  <DropdownComponent :offsety="'0'" @opened="openSelect" @click.stop>
    <template #trigger>
      <div class="actions-container">
        <div class="actions" :class="{ 'is-visible': isVisible }">
          <TeleportTooltip placement="top" :content="$t('login-login')">
            <span class="flex pointer" :class="[{ disabled: isDisabled, 'is-hidden': isHidden }, 'login']">
              <img :src="iconSrc" :alt="'login'" :class="['hover', { 'is-loading': isLoading }]">
            </span>
          </TeleportTooltip>
        </div>
      </div>
    </template>
    <template #content="scopeProps">
      <div class="dropdown-item-container">
        <img
          class="close-x"
          src="@/assets/common/close.svg"
          @click.stop="
            () => {
              scopeProps.close()
            }
          "
        >
        <div class="title">
          {{ $t('select-user-account') }}
        </div>
        <div class="users-container">
          <div class="columns">
            <div class="column">{{ $t('org-login-account-name') }}</div>
            <div class="column">{{ $t('org-login-account-permissions') }}</div>
          </div>
          <div class="users">
            <div v-for="user in users" :key="user" class="user" @click="switchToUser(user)">
              <div class="user-name">
                <TeleportTooltip placement="top" :content="user.user.first_name + ' ' + user.user.last_name" :text-length="20">
                  <div>
                    {{ user.user.first_name + ' ' + user.user.last_name }}
                  </div>
                </TeleportTooltip>
                <TeleportTooltip placement="top" :content="user.user.email" :text-length="20">
                  <div>
                    {{ user.user.email }}
                  </div>
                </TeleportTooltip>
              </div>
              <div class="user-permissions">
                <div v-if="mapRolesContains(user.roles, UserRolesNames.PublisherOwner)" class="role">{{ $t('accounts-input-type-publisher') }}</div>
                <div v-if="mapRolesContains(user.roles, UserRolesNames.AgencyOwner)" class="role">{{ $t('accounts-input-type-agency') }}</div>
                <div v-if="mapRolesContains(user.roles, UserRolesNames.ContentOwner)" class="role">{{ $t('accounts-input-type-content') }}</div>
              </div>
            </div>
            <div v-element-visibility="onElementVisibility" />
            <LoadingSpinner v-if="loading" class="loading" />
          </div>
        </div>
      </div>
      <div
        @click="
          () => {
            scopeProps
          }
        "
      />
    </template>
  </DropdownComponent>
</template>

<script lang="ts" setup>
import { DropdownComponent, TeleportTooltip } from '@/components/common'
import { computed, ref, watch } from 'vue'
import iconSrc from '@/assets/icons/Icon_Login.svg'
import LoadingSpinner from '@/components/common/LoadingSpinner.vue'
import { apiService } from '@/services'
import { Role, User } from '@/services/organization-user/types'
import authService from '@/services/auth.service'
import { UserRolesNames } from '@/services/organization-user/constants'
import { SortOrder } from '@/interfaces'
const openedMenu = ref(false)
const loading = ref(false)
const visible = ref(false)
const props = defineProps<{ isVisible?: boolean; isDisabled?: boolean; isHidden?: boolean; isLoading?: boolean; orgId: number }>()
const resultsPerPage = ref(8)
const totalPages = ref(0)
const currentPage = ref(1)
import { vElementVisibility } from '@vueuse/components'
const fetchParams = computed(() => {
  return {
    results_per_page: resultsPerPage.value,
    sort_by: 'first_name',
    sort_order: SortOrder.Ascending,
    page_number: currentPage.value,
    organization_id: props.orgId,
    list_only: true
  }
})

function onElementVisibility(isVisible: boolean) {
  visible.value = isVisible
  if (!loading.value) {
    if (isVisible && openedMenu.value) fetchUsers(true)
  }
}

function mapRolesContains(roles: Role[], role: string) {
  return roles.find((userRole) => {
    return userRole.name === role
  })
}

async function switchToUser(user: User) {
  await authService.switchToRemoteAccount(user.user.organization_id, user.user.id)
}

watch(
  () => openedMenu.value,
  () => {
    if (openedMenu.value) {
      fetchUsers()
    } else {
      setTimeout(() => {
        users.value = []
      }, 200)
    }
  }
)

async function fetchUsers(loadMore = false) {
  if (loading.value || (totalPages.value <= currentPage.value && loadMore)) return
  loading.value = true
  if (loadMore) currentPage.value++
  const res = await apiService.organizationUser.getUsers(fetchParams.value)
  if (loadMore) {
    users.value = [...users.value, ...res.users]
  } else {
    users.value = res.users
  }
  users.value = users.value.filter((user) => user.roles.some((role) => role.name === 'publisher_owner' || role.name === 'agency_owner' || role.name === 'content_owner'))
  totalPages.value = Math.ceil(res.total / resultsPerPage.value)
  loading.value = false
}

function openSelect(opened: boolean) {
  openedMenu.value = opened
}
const users = ref([])
</script>

<style lang="scss" scoped>
:deep(.dropdown-item-container) {
  padding: 40px 24px !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 24px;
  width: 533px;
  cursor: default;

  .title {
    color: var(--body-text, #4c4c66);
    text-align: center;
    font-family: Nunito;
    font-size: 21px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .users-container {
    width: 100%;

    > .columns {
      grid-template-columns: 40% auto;
      display: grid;
      border-bottom: 1px solid var(--title-3, #dadbe8);

      .column {
        display: flex;
        color: var(--body-text, #4c4c66);
        font-family: Nunito;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.1px;
        padding: 8px 24px;
      }
    }

    > .users {
      width: 100%;
      max-height: 295px;
      overflow: auto;
      padding: 0px 4px 0px 0px;

      .user {
        grid-template-columns: 41% auto;
        display: grid;
        border-bottom: 1px solid var(--title-3, #dadbe8);
        cursor: pointer;

        &:hover {
          background: #f3f4fa;
        }

        .user-name {
          // display: flex;
          // flex-direction: column;
          // justify-content: center;
          align-items: flex-start;
          padding: 8px 12px 8px 24px;

          color: var(--body-text, #4c4c66);
          font-family: Nunito;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          letter-spacing: 0.1px;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: center;
          gap: 4px;

          > div {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }

        .user-permissions {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          justify-content: flex-start;
          align-self: center;
          padding: 8px 24px 8px 12px;

          .role {
            white-space: nowrap;
            padding: 3px 20px;
            overflow: hidden;
            min-width: 180px;
            width: 180px;
            height: fit-content;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            align-self: stretch;
            text-transform: none;
            margin: 4px 8px 4px 0;
            font-size: 12px;
            border-radius: 4px;
            border: 1px solid var(--D2D5EC, #d2d5ec);
            background: var(--FFFFFF, #fff);
            color: var(--body-text, #4c4c66);
          }
        }
      }
    }
  }

  .dropdown-item-container:hover {
    filter: none !important;
  }
}

.popper {
  display: none !important;
}

.actions {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 1.5rem;
  visibility: hidden;

  .refresh .is-loading {
    animation: spin 2s linear infinite;
  }

  &.is-visible {
    visibility: visible;
  }

  .is-hidden {
    display: none;
  }

  img {
    &:hover {
      filter: $truvid-filter-effect !important;
    }
  }
}

.actions-container {
  padding-right: 1rem;
  padding-left: 1rem;
  display: flex;
  justify-content: flex-end;
  width: 100%;
  &:hover {
    .actions {
      visibility: visible;
      opacity: 1;
    }
  }
}

.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 10vh;
}

.close-x {
  position: absolute;
  right: 30px;
  top: 55px;
  cursor: pointer;
}
</style>
