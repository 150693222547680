import { AgencyMostActiveAccounts } from '@/interfaces'
import { computed } from 'vue'

const agencyDashboardMostActiveAccounts = computed<AgencyMostActiveAccounts[]>(() => {
  return [
    {
      title: 'dashboard-agency-accounts-organization_name',
      sorted: true,
      sortable: true,
      orderBy: 'organization_name',
      sortBy: 'organization_name'
    },
    {
      title: 'dashboard-agency-accounts-fee',
      sorted: true,
      sortable: true,
      orderBy: 'commision',
      sortBy: 'commision'
    },
    {
      title: 'dashboard-agency-accounts-server_calls',
      sorted: true,
      sortable: true,
      orderBy: 'server_calls',
      sortBy: 'server_calls'
    },
    {
      title: 'dashboard-agency-accounts-revenue',
      sorted: true,
      sortable: true,
      orderBy: 'revenue',
      sortBy: 'revenue'
    },
    {
      title: 'dashboard-agency-accounts-agency-profit',
      sorted: true,
      sortable: true,
      orderBy: 'master_account_cost',
      sortBy: 'master_account_cost'
    },
    {
      title: 'dashboard-agency-accounts-ecpm',
      sorted: true,
      sortable: true,
      orderBy: 'ecpm',
      sortBy: 'ecpm'
    },
    {
      title: 'dashboard-agency-accounts-fill_rate',
      sorted: true,
      sortable: true,
      orderBy: 'fill_rate',
      sortBy: 'fill_rate'
    }
  ]
})

export default agencyDashboardMostActiveAccounts
